import React from "react"
import HeaderStyle1 from "../components/Header/HeaderStyle1"
import SectionTitle from "../components/SectionTitle/SectionTitle"
import FooterStyle1 from "../components/Footer/FooterStyle1"
import Badge from 'react-bootstrap/Badge'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import cities from "../components/cities"
import {Helmet} from "react-helmet";
import {FaBook} from "react-icons/fa"
import Ads from "../images/ads.jpg"

const City2page = (props) => {
  const {pathContext} = props
  const city = pathContext.city
  const district_sales = pathContext.district_sales

  const cityInfo = cities[city]

  const neighbourElements = []
  if (cityInfo.neighbourInfos) {
    for (const info of cityInfo.neighbourInfos) {
      if (info.type === 'text') {
        neighbourElements.push(
          <p key={info.value.length}>
            { info.value }
          </p>
        )
      } else if (info.type === 'img') {
        neighbourElements.push(
          <p key={info.value.length}>
            <img src={ info.value } alt={ info.desc } />
          </p>
        )
      } else if (info.type === 'link') {
        neighbourElements.push(
          <a href={info.value}><span><FaBook /></span>{info.desc}</a>
        )
        neighbourElements.push(
          <br/>
        )
      }
    }
  }

  const elements = []
  for (const district in district_sales) {
    const dates = district_sales[district].map(function(staticsItem){return staticsItem.date;});

    const median_sales_prices = []
    const home_solds = []
    const new_listings = []
    const inventories = []
    const days_on_markets = []
    const average_sale_to_listings = []
    for (const district_sales_item of district_sales[district]) {
      if (district_sales_item["median_sales_price"] !== null) {
        median_sales_prices.push(district_sales_item["median_sales_price"]);
      }
      if (district_sales_item["home_sold"] !== null) {
        home_solds.push(district_sales_item["home_sold"]);
      } else {
        home_solds.push(0);
      }
      if (district_sales_item["new_listing"] !== null) {
        new_listings.push(district_sales_item["new_listing"]);
      } else {
        new_listings.push(0);
      }
      if (district_sales_item["inventory"] !== null) {
        inventories.push(district_sales_item["inventory"]);
      } else {
        inventories.push(0);
      }
      if (district_sales_item["days_on_market"] !== null) {
        days_on_markets.push(district_sales_item["days_on_market"]);
      }
      if (district_sales_item["average_sale_to_listing"] !== null) {
        average_sale_to_listings.push(district_sales_item["average_sale_to_listing"] - 1);
      }
    }
    
    const options5 = {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          scrollPositionX: 1
        }
      },
      title: {
        text: district + '房屋中位价走势'
      },
      xAxis: {
        categories: dates.slice(-24),
        type: 'datetime',
        labels: {
          overflow: 'justify'
        }
      },
      yAxis: {
        title: {
          text: 'Price'
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
      },
      tooltip: {
        valueSuffix: 'K'
      },
      plotOptions: {
        spline: {
          lineWidth: 4,
          states: {
            hover: {
              lineWidth: 5
            }
          },
          marker: {
            enabled: false
          }
        }
      },
      series: [
        {
          name: '中位价',
          data: median_sales_prices.slice(-24)
        }
      ],
      navigation: {
        menuItemStyle: {
          fontSize: '10px'
        }
      },
      credits: {
        text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
      }
    }

    const options6 = {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          scrollPositionX: 1
        }
      },
      title: {
        text: district + '售出房屋数量'
      },
      xAxis: {
        categories: dates.slice(-24),
        type: 'datetime',
        labels: {
          overflow: 'justify'
        }
      },
      yAxis: {
        title: {
          text: 'Transaction'
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
      },
      tooltip: {
        valueSuffix: ' transaction'
      },
      plotOptions: {
        spline: {
          lineWidth: 4,
          states: {
            hover: {
              lineWidth: 5
            }
          },
          marker: {
            enabled: false
          }
        }
      },
      series: [
        {
          name: '售出数量',
          data: home_solds.slice(-24)
        }
      ],
      navigation: {
        menuItemStyle: {
          fontSize: '10px'
        }
      },
      credits: {
        text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
      }
    }
  
    const options7 = {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          scrollPositionX: 1
        }
      },
      title: {
        text: district + '每月上市房屋数量'
      },
      xAxis: {
        categories: dates.slice(-24),
        type: 'datetime',
        labels: {
          overflow: 'justify'
        }
      },
      yAxis: {
        title: {
          text: 'Listing'
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
      },
      tooltip: {
        valueSuffix: ' listing'
      },
      plotOptions: {
        spline: {
          lineWidth: 4,
          states: {
            hover: {
              lineWidth: 5
            }
          },
          marker: {
            enabled: false
          }
        }
      },
      series: [
        {
          name: '上市房屋数量',
          data: new_listings.slice(-24)
        }
      ],
      navigation: {
        menuItemStyle: {
          fontSize: '10px'
        }
      },
      credits: {
        text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
      }
    }
  
    const options8 = {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          scrollPositionX: 1
        }
      },
      title: {
        text: district + '每月库存变化'
      },
      xAxis: {
        categories: dates.slice(-24),
        type: 'datetime',
        labels: {
          overflow: 'justify'
        }
      },
      yAxis: {
        title: {
          text: ''
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
      },
      tooltip: {
        valueSuffix: ' transaction'
      },
      plotOptions: {
        spline: {
          lineWidth: 4,
          states: {
            hover: {
              lineWidth: 5
            }
          },
          marker: {
            enabled: false
          }
        }
      },
      series: [
        {
          name: '库存',
          data: inventories.slice(-24)
        }
      ],
      navigation: {
        menuItemStyle: {
          fontSize: '10px'
        }
      },
      credits: {
        text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
      }
    }
  
    const options9 = {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          scrollPositionX: 1
        }
      },
      title: {
        text: district + '平均售出时间'
      },
      xAxis: {
        categories: dates.slice(-24),
        type: 'datetime',
        labels: {
          overflow: 'justify'
        }
      },
      yAxis: {
        title: {
          text: 'Day'
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
      },
      tooltip: {
        valueSuffix: ' day'
      },
      plotOptions: {
        spline: {
          lineWidth: 4,
          states: {
            hover: {
              lineWidth: 5
            }
          },
          marker: {
            enabled: false
          }
        }
      },
      series: [
        {
          name: '天数',
          data: days_on_markets.slice(-24)
        }
      ],
      navigation: {
        menuItemStyle: {
          fontSize: '10px'
        }
      },
      credits: {
        text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
      }
    }
  
    const options10 = {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          scrollPositionX: 1
        }
      },
      title: {
        text: district + '最终成交价格/上市价格'
      },
      xAxis: {
        categories: dates.slice(-24),
        type: 'datetime',
        labels: {
          overflow: 'justify'
        }
      },
      yAxis: {
        title: {
          text: 'Ratio'
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
      },
      tooltip: {
        valueSuffix: ' '
      },
      plotOptions: {
        spline: {
          lineWidth: 4,
          states: {
            hover: {
              lineWidth: 5
            }
          },
          marker: {
            enabled: false
          }
        }
      },
      series: [
        {
          name: '百分比',
          data: average_sale_to_listings.slice(-24)
        }
      ],
      navigation: {
        menuItemStyle: {
          fontSize: '10px'
        }
      },
      credits: {
        text: Math.random() > 0.5 ? "Jing Xue (650)542-1628" : "Wechat: TopAgentJingXue"
      }
    }

    const descElements = []
    if (cityInfo.neighbours && cityInfo.neighbours[district] !== undefined) {
      for (const neighbourDesc of cityInfo.neighbours[district]) {
        descElements.push(
          <p key={neighbourDesc.length}>
            { neighbourDesc }
          </p>
        )
      }
    }

    elements.push(
      <section
        className="pad-t30 pad-b30 parallax"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title={district + "区近24个月房产市场"} />
            </div>
          </div>
          {descElements.length > 0 && descElements}
          <p>{city}的{district}区上月上市房屋{new_listings[new_listings.length - 1]}套，平均库存{inventories[inventories.length - 1]}套，售出房屋{home_solds[home_solds.length - 1]}套，
          中位价为{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(median_sales_prices[median_sales_prices.length - 1])},
          平均在售天数为{days_on_markets[days_on_markets.length - 1]}天，
          平均加价幅度为{new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits:2 }).format(average_sale_to_listings[average_sale_to_listings.length - 1])}。</p>
          <div className="row">
            <div className="col-md-6 text-center">
              <iframe
                title={district}
                src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyDr7gRsDPCn7v3tZ1jlD1_oOyiu9dg9M6s&zoom=14&q=" + district + ',' + city + ",CA"}
                width="100%"
                height="100%"
                style={{ border: "0" }}
              ></iframe>
            </div>
            <div className="col-md-6 text-center">
              <HighchartsReact
                highcharts={Highcharts}
                options={options5}
              />
            </div>
            <div className="col-md-6 text-center">
              <HighchartsReact
                highcharts={Highcharts}
                options={options6}
              />
            </div>
            <div className="col-md-6 text-center">
              <HighchartsReact
                highcharts={Highcharts}
                options={options7}
              />
            </div>
            <div className="col-md-6 text-center">
              <HighchartsReact
                highcharts={Highcharts}
                options={options8}
              />
            </div>
            <div className="col-md-6 text-center">
              <HighchartsReact
                highcharts={Highcharts}
                options={options9}
              />
            </div>
            <div className="col-md-6 text-center">
              <HighchartsReact
                highcharts={Highcharts}
                options={options10}
              />
            </div>
          </div>
        </div>
      </section>
    )
  
  }

  const title = city + "买房 | " + city + "房市分析 | " + city + "房产地图 | " + city + "房价 | " + cityInfo.title + " | " + city + "学区分析 学校排名 | " + city +  " 简介 | Jing Xue | 三谷湾区置业专家 三谷Top华人房产经纪 | " + city + "房产经纪 | " + city + "房产"
  const description = cityInfo && cityInfo.description.length > 0 ? cityInfo.description[0] : title
  
  return (
    <>
      <HeaderStyle1 />
      <div className="application">
          <Helmet>
              <meta charSet="utf-8" />
              <title>{title}</title>
              <meta name="description" content={description} />
              <meta name="Keywords" content={ city + "买房," + city + "房产经纪," + city + "简介," + city + "学区分析," +  city + "房产," + city + "投资房," + city + "房价," + "湾区,三谷,华人房产经纪,南湾,东湾,湾区学校排名,美国买房,美国房产,美国房价"}></meta>
              <meta name="author" content="Jing Xue" />
              <link rel="canonical" href="https://www.jingxuehomes.com" />
          </Helmet>
      </div>
      {/* Start image content Service Section*/}
      <section className="pad-t30 pad-b30">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4
                  style={{
                    fontSize: "25px",
                    lineHeight: "40px",
                    margin: "0 0 10px",
                    textTransform: "uppercase",
                  }}
                >
                  Jing Xue湾区房产地图 - { city }
                </h4>
                <h5>
                  { cityInfo.title }
                </h5>
            </div>
          </div>
          <iframe
            title={city}
            src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyDr7gRsDPCn7v3tZ1jlD1_oOyiu9dg9M6s&zoom=13&q=" + city + "+California"}
            width="100%"
            height="500px"
            style={{ border: "0" }}
          ></iframe>
        </div>
      </section>

      {/* Start Portfolio Section*/}
      {neighbourElements.length > 0 && (<section className="pad-t30 pad-b30">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title={city + " 城市基本信息"} />
            </div>
          </div>
          { neighbourElements }
        </div>
      </section>)}
      {/* End Portfolio Section*/}
      {elements}

      {/* Start Footer Section*/}
      <FooterStyle1 />
      {/* End Footer Section*/}
    </>
  )
}

export default City2page
